@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Noto Serif TC', serif,'Noto Sans TC', sans-serif;
  }
}

.submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.submenu.open {
  max-height: 1000px;
}


.point::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 2px rgba(1,76,105,1);
  animation: fadeIn 1s infinite alternate;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
